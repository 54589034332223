import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Loader } from "../../component";
import { ProfileContext } from "./context";
import BankInput from "../../component/bank_input";
import { CreateClientBankAccountInput, createClientBankAccount } from "../../api";
import { PersonaContext } from "../login/context";

interface CreateBankAccountState {
    loading: boolean
    form: CreateClientBankAccountInput
}

export default function CreateBankAccount() {
    const profile = useContext(ProfileContext)
    const persona = useContext(PersonaContext)
    const [state, setState] = useState<CreateBankAccountState>({
        loading: false,
        form: {
            clientId: persona.clientId,
            accountId: persona.accountId,
            bic: "",
            accountName: "",
            accountNumber: "",
        }
    })
    const navigate = useNavigate()

    useEffect(() => {
        if (!persona.canInvest) {
            navigate("/settings/bank-account/list")
        }
    }, [])

    const submitDisabled = state.form.clientId === "" || state.form.accountId === "" || state.form.bic === "" || state.form.accountName === ""|| state.form.accountNumber === "" || state.form.accountNumber.length < 5

    const handleInputChange = (field: string, value: string) => {
        setState(prev => ({
            ...prev,
            form: {
                ...prev.form,
                [field]: value
            }
        }))
    }

    const handleSubmit = async () => {
        try {
            setState(prev => ({...prev, loading: true}))
            const resp = await createClientBankAccount(state.form)
            if (resp.status >= 400) {
                setState(prev => ({...prev, loading: false}))
                return
            }
            profile.reload()
            navigate("/settings/bank-account/list")
        } catch (err) {
            setState(prev => ({...prev, loading: false}))
        }
    }


    return <div className="flex flex-col h-full">
        <div>
            <div className="relative h-32 text-center hg-bg-blue" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
                <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-zxl cursor-pointer" onClick={() => navigate("/settings/bank-account/list")}/>
                <h1 className="pt-12 text-xl text-white pl-3 pr-6">
                    New bank account
                </h1>
            </div>
        </div>
        <div className="flex flex-col justify-between h-full p-5">
            <div className="flex flex-col gap-5">
                <div className="flex items-center gap-2">
                    <label className="w-2/6 font-medium">Holder's name</label>
                    <input type="text" className="border p-2 rounded-full w-4/6" placeholder="Your bank account name" onChange={ev => handleInputChange("accountName", ev.currentTarget.value)}/>
                </div>
                
                <div className="flex items-center gap-2">
                    <label className="w-2/6 font-medium">Bank</label>
                    <BankInput onChange={ev => handleInputChange("bic", ev.currentTarget.value)} className="w-4/6 cursor-pointer rounded-3xl overflow-hidden"/>
                </div>
                
                <div className="flex items-center gap-2">
                    <label className="w-2/6 font-medium">Account no.</label>
                    <input type="number" className="border p-2 rounded-full w-4/6" placeholder="1234567890" onChange={ev => handleInputChange("accountNumber", ev.currentTarget.value)}/>
                </div>
            </div>
            
            <div className="flex justify-center">
                <button type="submit" disabled={state.loading || submitDisabled} onClick={handleSubmit} className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300">
                    {!state.loading && "Create"}
                    {state.loading && <Loader classNames="text-blue-300 h-5 w-5"/>}
                </button>
            </div>
        </div>
        
    </div>
}
